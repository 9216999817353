export const STRINGS = {
    EDIT_STYLE: 'EDIT STYLE',
    EDIT_ITEM: 'EDIT ITEM',
    EDIT_STYLE_ALERT_MESSAGE:
        'The style will be unpublished from Taffi and need to be published again. Do you want to continue to edit the',
    CANNOT_EDIT_STYLE_ALERT_MESSAGE: 'This style was not created using Taffi web app. So this style cannot be edited.',
    MY_ITEMS: 'My Items',
    SHOP_STYLE: 'SHOP THE STYLE',
    SHOP_ITEM: 'SHOP THE ITEM',
    MORE: 'More',
    NO_NOTES: 'NO NOTES ADDED',
    ADD_A_NOTE: 'ADD A NOTE HERE',
    ADD_NOTE: 'Add Note',
    EDIT_NOTE: 'Edit Note',
    UPDATE: 'Update',
    SKIP: 'Skip',
    SAVE: 'Save',
    RESPOND_LATER: 'Respond Later',
    OKAY: 'Okay',
    DONE: 'Done',
    LETS_GET_STARTED: 'Let us get started.',
    TRENDING_QUESTION_MESSAGE: 'You can try asking me the following suggested questions.',
    SHOW_MORE: 'SHOW MORE...',
    ADD_FEEDBACK: 'Add Feedback',
    FEEDBACK_RECEIVED: 'Feedback Received',
    FEEDBACK_SUCCESS_MESSAGE:
        'Your valuable feedback have been received. Anticipate refined and personalized recommendations exclusively for you.',
    STYLE: 'style',
    STYLE_ASSISTANT: 'style_assistant',
    FEEDBACK_TITLE:
        'We strive for perfection. Kindly share where we missed the mark, aiding us to refine and personalize our recommendations for you.',
    FEEDBACK_REJECT_TITLE: 'Kindly share why this style missed the mark aiding the stylist to refine and improve.',
    SUBMIT: 'Submit',
    EDIT: 'Edit',
    REQUIRED_FIELD: 'This field is required',
    WHAT_MISSING: 'Let us know what is missing.',
    REQUEST_A_STYLE: 'Request a Style',
    REVIEW_A_STYLE: 'Review a Style',
    STYLE_REVIEW: 'Review Style',
    ITEM_REVIEW: 'Review Rejected Style Items',
    REJECT: 'Reject',
    APPROVE: 'Approve',
    REJECTING: 'Rejecting...',
    APPROVING: 'Approving...',
    STYLE_REJECTED: 'Style Rejected!',
    STYLE_APPROVED: 'Style Approved!',
    FEEDBACK_REVIEW_SUCCESS: 'Your valuable feedback has been successfully submitted.',
    NO_STYLE_FOR_REVIEW: 'No Styles for Review',
    NO_STYLE_FOR_REVIEW_MESSAGE: 'Currently, there are no additional styles available to review. Kindly revisit later.',
    STYLIST_STYLE_PUBLISHED: 'Amazing Work!',
    REVIEW_STYLE_PUBLISHED: 'Style Published',
    REVIEW_STYLE_REJECTED: 'Style Rejected',
    REVIEW_STYLE_REJECTED_DETAIL: 'The reviewed style has been rejected and your feedback has been submitted.',
    VIEW_STYLE_DETAIL: 'View Style Detail',
    REVIEW_MORE_STYLES: 'Review More Styles',
    REVIEW_STYLES_LATER: 'Review Styles Later',
    FIX_STYLE_NOW: 'Fix the style',
    FIX_STYLE_NOW_INFO:
        'You will be next taken to the Style Editor where you can change the style to make it suitable for consumers.',
    CANNOT_BE_FIXED: 'Cannot be fixed',
    CANNOT_BE_FIXED_INFO:
        'Please help us understand why this style cannot be fixed. You will be guided to review each item in the style and provide specific feedback.',
    START_ITEMS_REVIEW: 'Start Items Review →',
    START_STYLE_EDIT: 'Start Style Edit →',
    NEXT: 'Next',
    UNSUPPORTED_BROWSER: 'Unsupported Browser',
    SIGN_UP: 'Signup',
    LOGIN: 'Login',
    LINK_COPIED: 'Link copied to clipboard',
    ADD_STYLE_TO_REQUEST: 'Add Style to Style Request',
    ADD_ITEM_TO_REQUEST: 'Add Item to Style Request',
    NO_INTERNET: 'No Internet',
    NO_INTERNET_MESSAGE:
        "Oops, it looks like you're offline with no internet! To continue, please check your internet connection and try again.",
    HOME_BANNER_TOP_TEXT: 'Amira: Your 24/7 Personal Stylist',
    HOME_BANNER_BOTTOM_TEXT: 'The First AI Style Adviser Of The Middle East',
    HOME_BANNER_CTA_TEXT: 'Meet Amira',
    EXPERT_STYLIST_AMIRA: 'Please ask expert stylist Amira for a personalized style.',
    ASK_AMIRA_NOW: 'Ask Amira Now',
    HOME_DISCOVER_YOUR_STYLE_TITLE: 'Discover Your Style With Amira',
    HOME_NO_PERSONALIZED_STYLES:
        'There are no personalized styles for you at this moment. You can ask Amira for personalized styles.',
    WARDROBE_EMPTY_LABEL: 'Upload items to your wardrobe now for personalized styles and tips from Amira.',
    SHOP_NOW: 'SHOP NOW',
    VIEW_STYLE: 'View Style',
    VIEW_ITEM: 'View Item',
    READ_MORE: 'Read More',
    REJECT_ITEM: 'Reject Item',
    APPROVE_ITEM: 'Approve Item',
    REJECT_AT_LEAST_ONE: 'You need to Reject and provide feedback for at least one item.',
    WHY_CHANGED: 'Why Changed?',
    SAVE_TO_DRAFT: 'Save to Draft',
    STYLE_PUBLISHED: 'Your style has been published to Taffi and is available for purchase to consumers.',
    STYLE_SAVED_AS_DRAFT: 'Your style is saved as draft.',
    SUBMIT_FEEDBACK_CONFIRM: 'Are you sure you want to submit the feedback?',
    NO_FEEDBACK_SELECTED: 'Please select Feedback Options before submitting.',
    ASK_YOUR_STYLE_QUESTION: 'Ask your style question…',
    STYLE_DETAILS: 'Style Details',
    THANKS_FOR_FEEDBACK: 'Thank You For Your Feedback!',
    GLAD_I_COULD_HELP: 'Glad I could help!',
    PLEASE_CREATE_PROFILE: 'To save your feedback and improve your experiences, please create a profile.',
    HYPER_PERSONALIZED_MESSAGE: 'This will help to provide hyper-personalized recommendations.',
    CREATE_PROFILE: 'Create Profile',
    ALREADY_HAVE_A_PROFILE: 'Already have a profile',
    EXPERT_STYLIST_ACCESS: 'Expert Stylist Access',
    HYPER_PERSONALIZED_LOOKS: 'Hyper-Personalized Looks',
    PERSONAL_DIGITAL_WARDROBE: 'Personal Digital Wardrobe',
    COMPLETE_STYLE_QUIZ: 'Complete Style Quiz',
    STYLE_IT_RIGHT: 'Style It Right',
    DISCOVER_YOUR_STYLE: 'Discover Your Style',
    PERSONALIZED_STYLES: 'Personalized Styles',
    STYLE_RECOMMENDATION: 'Style Recommendations',
    STYLE_SEARCH: 'Style Search',
    STATIC_PRODUCT_DETAIL_PAGE: '/product-detail-page',
    STATIC_PRODUCT_LISTING_PAGE: '/product-listing-page',
    STATIC_PRODUCT_SEARCH_PAGE: '/product-search',
    WANT_MORE_PERSONALIZED_STYLE: 'Want more personalized styles ?',
    NO_MORE_STYLES: 'No More Styles',
    DID_NOT_LIKE_STYLE: 'Didn’t like any styles',
    ASK_AMIRA_FOR_PERSONALIZED_STYLE: 'Please ask expert stylist <u>Amira</u> for a personalized style.',
    CHECKOUT_NEW_STYLES_MESSAGE:
        'Please click below to checkout new styles and let us know about your likes and dislikes.',
    CHECKOUT_NEW_STYLES: 'Checkout New Styles',
    NO_MATCHING_STYLES: 'No Matching Styles',
    NO_PERSONALIZED_STYLES: 'There are no personalized styles for you at this moment.',
    NO_READY_STYLES: "Sorry we don't have any ready styles matching your requirements. ",
    CLEAR_FILTERS:
        'Please click <u>Clear Filters</u> above or ask expert stylist <u>Amira</u> for a personalized style.',
    STYLE_NOT_FOUND: 'Style Not Found.',
    STYLE_NOT_AVAILABLE: 'Sorry, the style your are looking for is no longer available.',
    OCCASIONS: 'Occasions',
    SEASONS: 'Seasons',
    ASK_AMIRA: 'Ask Amira',
    POWER_BY: 'Powered by',
    STYLE_IT_RIGHT_SUBTITLE: 'Find matching style recommendations for this item.',
    PERSONALIZE_IT_RIGHT: 'Personalize It Right',
    PERSONALIZE_IT_RIGHT_SUBTITLE: 'Get bespoke styling advice and recommendations.',
    DRESS_IT_RIGHT: 'Dress It Right',
    DRESS_IT_RIGHT_SUBTITLE: 'Find suitability and avoid fashion faux pas.',
    REQUEST_SIGNUP_LOGIN: 'Unlock Personalized Experience',
};
