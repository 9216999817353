import { styled } from '@material-ui/styles';

export const FlexBox = styled('div')((props: any) => ({
    display: 'flex',
    alignItems: props?.alignitems,
    justifyContent: props?.justifycontent,
    flexDirection: props?.flexdirection,
    margin: props?.margin,
    position: props?.position,
}));
