export enum OrderStatusType {
    Canceled = 'canceled',
    Completed = 'completed',
    Declined = 'declined',
    Delivered = 'delivered',
    PartiallyRefunded = 'partially_refunded',
    PartiallyShipped = 'partially_shipped',
    PaymentFailed = 'payment_failed',
    PaymentPending = 'payment_pending',
    PaymentSuccess = 'payment_success',
    PreparingToShip = 'preparing_to_ship',
    Refunded = 'refunded',
    Shipped = 'shipped',
}

export enum ItemOrderStatusType {
    PreparingToShip = 'preparing_to_ship',
    Shipped = 'shipped',
    Delivered = 'delivered',
    Canceled = 'canceled',
    Declined = 'declined',
    Refunded = 'refunded',
}
