import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import React, { Suspense } from 'react';
import LoadingDataSpinner from 'component/loading_spinner/loading_data_spinner';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { database } from './firebase';
import EventEmitter from 'reactjs-eventemitter';
import { EmitterEvents } from './constants/event-emitter';

export const history = createBrowserHistory();

const hasStorageAccess = () => {
    try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
        // Check Session Storage
        sessionStorage.setItem('test', 'test');
        sessionStorage.removeItem('test');
        return true;
    } catch (error) {
        window.parent.postMessage(
            {
                noStorageAccess: true,
                isMessageFromTaffi: true,
            },
            '*',
        );
        return false;
    }
};

const Component = hasStorageAccess()
    ? React.lazy(() => import('./TaffiAppProvider'))
    : React.lazy(() => import('./containers/no_storage_container'));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Suspense fallback={<LoadingDataSpinner />}>
        <Component />
    </Suspense>,
);

serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
//     onUpdate: (registration) => {
//         if (registration && registration.waiting) {
//             registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//         }
//     },
// });

document.addEventListener('visibilitychange', async () => {
    // Foreground Detection
    if (document.visibilityState === 'visible') {
        try {
            const newVersion = await database.realtimeDatabase.ref('config').child('version').get();
            if (newVersion.val() && newVersion.val() !== process.env.REACT_APP_WEBAPP_VERSION) {
                console.log('%cNEW VERSION AVAILABLE', 'color: red');
                console.log(newVersion.val());
                EventEmitter.dispatch(EmitterEvents.NEW_VERSION_AVAILABLE, {
                    version: newVersion.val(),
                });
            }
        } catch (e: any) {
            console.error('Ignore this error at local because it requires https', e);
        }
    }
});
