import { makeStyles } from '@material-ui/core';
const bottomNavigationOffest = 56;

export const useStyles = makeStyles(() => ({
    loadingBg: {
        top: 0,
        bottom: bottomNavigationOffest,
        left: 0,
        width: '100%',
        // opacity: 0.5,
        zIndex: 999999,
        position: 'absolute',
    },
    loadingRouting: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'ease-in-out',
        zIndex: 9999,
        top: 0,
        bottom: bottomNavigationOffest,
        left: 0,
        position: 'fixed',
        width: '100vw',
    },
}));
