import { createTheme } from '@material-ui/core/styles';
const colorPrimary = '#1A1A1A';
export default createTheme({
    palette: {
        primary: {
            main: `${colorPrimary}`,
            dark: '#1a1a1a80',
            light: '#F2F2F2',
        },
        grey: {
            100: '#C1C1C1',
            200: '#D1D1D1',
            300: '#767676',
            400: '#484848',
            500: '#A3A3A3',
        },
        info: {
            main: '#82BEE8',
            100: '#E5F2FB',
            200: '#CCE5F7',
            300: '#99CBEF',
            light: '#1400B9',
            500: '#28A745',
        },
        background: {
            default: '#323232',
            paper: '#fff',
        },
        warning: {
            main: '#F39656',
            100: '#FDEADD',
            200: '#F5DFCE',
            300: '#F8C099',
            400: '#F5AB77',
            500: '#F2D4BD',
        },
        secondary: {
            main: '#E8C6A9',
            100: '#FAF4EE',
            200: '#F6E8DD',
            300: '#F1DDCB',
            400: '#EDD1BA',
            500: '#FBF4EE',
        },
    },
    typography: {
        h2: {
            fontFamily: 'Raleway',
            fontWeight: 700,
            fontSize: '2.5rem',
            color: `${colorPrimary}`,
            lineHeight: 1.5,
        },

        subtitle1: {
            fontSize: '1.25rem',
            fontWeight: 300,
            color: `${colorPrimary}`,
        },

        body1: {
            fontSize: '1.75rem',
            fontWeight: 400,
            color: `${colorPrimary}`,
            fontFamily: 'Raleway',
        },
    },
    overrides: {
        MuiInputLabel: {
            root: {
                color: `${colorPrimary}`,
                fontSize: '3rem',
                fontFamily: 'GTSuper',
                top: '-13px!important',
                '&$focused': {
                    fontSize: '18px',
                },
            },
        },
        MuiInput: {
            root: {
                color: `${colorPrimary}`,
                fontWeight: 300,
            },
            underline: {
                '&:before': {
                    borderBottom: `3px solid ${colorPrimary}`,
                },
                '&$focused': {
                    borderBottom: `2px solid ${colorPrimary}`,
                    bottom: '10px',
                },
            },
        },
    },
});
