import { FlexBox } from 'component/customize_style_component/styled_box';
import themes from 'styles/themes';
import loadingAnimation from '../../assets/img/loading-animations.gif';
import { useStyles } from './style';
import { animated, useSpring } from '@react-spring/web';

function LoadingDataSpinner(props) {
    const classes = useStyles();
    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 500 },
    });
    return (
        <animated.div className={classes.loadingBg} style={{ backgroundColor: themes.palette.common.white, ...spring }}>
            <FlexBox flexdirection="column" justifycontent="center" alignitems="center" style={{ height: '100%' }}>
                <img src={loadingAnimation} alt="" style={{ width: 300, height: 300 }} />
            </FlexBox>
        </animated.div>
    );
}

export default LoadingDataSpinner;
