export enum NotificationVerb {
    StyleRequestItemAdded = 'STYLE_REQUEST_ITEM_ADDED',
    StyleRequestCompleted = 'STYLE_REQUEST_COMPLETED',
    StyleRequestRestyle = 'STYLE_REQUEST_RESTYLE',
    StyleItemAdded = 'STYLE_ITEM_ADDED',
    StyleItemRemoved = 'STYLE_ITEM_REMOVED',
    StyleLikeAdded = 'STYLE_LIKE_ADDED',
    StyleLikeRemoved = 'STYLE_LIKE_REMOVED',
    StyleDislikeAdded = 'STYLE_DISLIKE_ADDED',
    StyleDislikeRemoved = 'STYLE_DISLIKE_REMOVED',
    OrderPaymentSuccess = 'ORDER_PAYMENT_SUCCESS',
    OrderShipped = 'ORDER_SHIPPED',
    UserStylistApproved = 'USER_STYLIST_APPROVED',
    OrderUpdate = 'ORDER_UPDATED',
    OrderDelivered = 'ORDER_DELIVERED',
    StyleGuideReady = 'STYLE_GUIDE_READY',
    SessionScheduled = 'SESSION_SCHEDULED',
    SessionCancelled = 'SESSION_CANCELLED',
    SessionRescheduled = 'SESSION_RESCHEDULED',
    StylistAddedOutcome = 'STYLIST_ADDED_OUTCOME',
    CustomerViewedStyle = 'CUSTOMER_VIEWED_STYLE',
    CustomerReacted = 'CUSTOMER_REACTED',
}
